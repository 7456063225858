/**
 * Regularly import this file on either server or client.
 * DO NOT PUT SENSITIVE INFORMATION LIKE ACCESS DATA HERE.
 * THIS FILE WILL BE BUNDLED TO THE CLIENT!
 */

const ENV_OVERWRITES: any = process.env.OVERWRITES || {}

interface ConfigOptions {
  $default?: any
  development?: any
  production?: any
  staging?: any
}

export class Config {
  PORT!: number
  PUBLIC_URL!: string
  API_URL!: string
  GRAPHQL_API_URL!: string
  REST_API_URL!: string
  GRAPHQL_WS_URL!: string
  COOKIE_DOMAIN!: string

  PLAID_ENV!: string
  PLAID_PUBLIC_KEY!: string

  FULL_STORY_ORG_ID!: string

  STRIPE_KEY!: string

  FACEBOOK!: {
    APP_ID: string
  }

  SEGMENT!: string

  GOOGLE_ANALYTICS!: string

  GOOGLE_OPTIMIZE_EXPERIMENT_ID!: string

  SENTRY_DSN!: string

  CLOUDINARY_CLOUD_NAME!: string

  CLOUDINARY_API_URL!: string

  BACKEND_ROOT_URL!: string

  HELP_URL!: string

  WHAT_IS_529_URL!: string

  BANK_OF_AMERICA_BROKER_ID!: number

  DEFAULT_SEO = {
    title: 'Backer',
    description:
      // tslint:disable-next-line
      'Easy way to sign up for 529 College Savings Plans and save more with gifts from family and friends, a 529 calculator, reviews of state plans, and information on contribution limits.',
    keywords:
      '529 plan, 529 calculator, 529 account, college, savings, tax-free, contribution limits, fund',
    openGraph: {
      type: 'website',
      locale: 'en_US',
      url: 'https://backer.com',
      title: 'Backer | The Social College Fund',
      description:
        'Start a tax-free 529 college savings plan in 5 minutes or connect your existing 529 plan. Easily invite family and friends and discover more ways to save for college, like our popular cashback rewards program.',
      image: 'https://backer.com/images/og-facebook.jpg',
      site_name: 'Backer',
      imageWidth: '1200',
      imageHeight: '1200'
    },
    twitter: {
      handle: '@backer529',
      cardType: 'summary',
      image: 'https://backer.com/images/og-twitter.jpg'
    }
  }

  constructor(public readonly env: string) {}

  init() {
    this.assign('PORT', {
      $default: Number(process.env.WEB_PORT) || 3082
    })

    this.assign('PUBLIC_URL', {
      $default: `http://localhost:${this.PORT}`,
      production: 'https://app.backer.com',
      staging: 'https://app.backer.link'
    })
    this.assign('REST_API_URL', {
      $default: 'http://localhost:4000/restapi',
      production: 'https://backer.com/restapi',
      staging: 'https://backer.link/restapi'
    })
    this.assign('GRAPHQL_API_URL', {
      $default: 'http://localhost:4000/api',
      production: 'https://backer.com/api',
      staging: 'https://backer.link/api'
    })
    this.assign('GRAPHQL_WS_URL', {
      $default: 'ws://localhost:4000/socket',
      production: 'wss://app.collegebacker.com/socket',
      staging: 'wss://backer.link/socket'
    })
    this.assign('COOKIE_DOMAIN', {
      $default: '',
      production: '.backer.com',
      staging: '.backer.link'
    })

    this.assign('PLAID_ENV', {
      $default: 'sandbox',
      production: 'production',
      staging: 'sandbox'
    })

    this.assign('PLAID_PUBLIC_KEY', {
      $default: '97ce77941181c53d3436d414e8ff99',
      production: '97ce77941181c53d3436d414e8ff99',
      staging: '97ce77941181c53d3436d414e8ff99'
    })

    this.assign('FULL_STORY_ORG_ID', {
      $default: '',
      production: 'o-19ZS7H-na1',
      staging: ''
    })

    this.assign('STRIPE_KEY', {
      $default:
        'pk_test_51HLHpGGz1i2SUAIhoNxZ6NFVr9dgwT5aOrbVIaLYsPYdeYQoXjoCjJ3fdPz9mzz0SMriyRIAgwG9COV8DVqizJS000m8IXKeD8',
      production:
        'pk_live_51HLHpGGz1i2SUAIhKHMXGQMyLdxzTOiOKOCD9m9JucA5nXzRsWa4ASLeuL8CCoSggcC3GKeEu64pSiLHCZDFGSmE00opR3uYgn',
      staging:
        'pk_test_51HLHpGGz1i2SUAIhoNxZ6NFVr9dgwT5aOrbVIaLYsPYdeYQoXjoCjJ3fdPz9mzz0SMriyRIAgwG9COV8DVqizJS000m8IXKeD8'
    })

    this.assign('FACEBOOK', {
      $default: {
        APP_ID: '1743893495897419'
      },
      production: {
        APP_ID: '1743893495897419'
      },
      staging: {
        APP_ID: '1743902312563204'
      }
    })

    this.assign('SEGMENT', {
      $default: 'Y50iahm7rR9PfW1Y71x2lhK1jN5r00eF',
      production: 'jCL2gIYVYO471nki5x80weAkF1aNQyKb',
      staging: 'Y50iahm7rR9PfW1Y71x2lhK1jN5r00eF'
    })

    this.assign('GOOGLE_ANALYTICS', {
      $default: 'UA-146475365-1',
      production: 'UA-61619903-1',
      staging: 'UA-146475365-1'
    })

    this.assign('GOOGLE_OPTIMIZE_EXPERIMENT_ID', {
      $default: 'l4wH9c5WSvaZ_HsT9N0YcA',
      production: 'm87MdwsBSWWWMUA_ZpbwoA',
      staging: 'XnTalaG7SFGVyRW2t5O5Xw'
    })

    this.assign('SENTRY_DSN', {
      $default: '',
      staging:
        'https://f112670669d04cc79f1b3160e02ec7cf@o162127.ingest.sentry.io/6383106',
      production:
        'https://f112670669d04cc79f1b3160e02ec7cf@o162127.ingest.sentry.io/6383106'
    })

    this.assign('BACKEND_ROOT_URL', {
      $default: 'http://localhost:4000',
      production: 'https://backer.com',
      staging: 'https://backer.link'
    })

    this.assign('HELP_URL', {
      $default: 'https://help.backer.com/hc/en-us/requests/new',
      production: 'https://help.backer.com/hc/en-us/requests/new',
      staging: 'https://help.backer.com/hc/en-us/requests/new'
    })

    this.assign('WHAT_IS_529_URL', {
      $default: 'https://backer.com/529-college-investment-plans',
      production: 'https://backer.com/529-college-investment-plans',
      staging: 'https://backer.com/529-college-investment-plans'
    })

    this.assign('CLOUDINARY_CLOUD_NAME', {
      $default: 'dgfjsma5p',
      production: 'dtdmzmuqd',
      staging: 'dgfjsma5p'
    })

    this.assign('CLOUDINARY_API_URL', {
      $default: `https://api.cloudinary.com/v1_1/${this.CLOUDINARY_CLOUD_NAME}/image/upload`
    })

    this.assign('BANK_OF_AMERICA_BROKER_ID', {
      $default: 1,
      production: 1,
      staging: 1
    })
  }

  private assign(key: keyof this, options: ConfigOptions): void {
    this[key] =
      ENV_OVERWRITES[String(key)] ||
      process.env[String(key)] ||
      // @ts-ignore @TODO fix me
      process.env[`REACT_APP_${key}`] ||
      // @ts-ignore
      options[this.env] ||
      options.$default
    if (this[key] === undefined) {
      // @ts-ignore @TODO fix me
      throw new Error(`Config is missing for ${key}`)
    }
  }
}

// Could be imported by the client or the server
const environment =
  typeof window !== 'undefined'
    ? // @ts-ignore
      window['ENV'] || 'development'
    : process.env.ENV || process.env.NODE_ENV || 'development'

const config = new Config(environment)
config.init()

export default config
