import gql from 'graphql-tag'
import config from 'config'
import { LabelTagColor } from 'modules/global/types'
import { buildPath, historyRef } from 'routes'
import { graphQLErrorHandler, sendToMobileApp } from 'utils'
import {
  EnrollmentStatus,
  Portfolio,
  ProgramManager,
  listOfEnrollmentStatusValues
} from './fundInterfaces'
import fragments from 'modules/enrollment/graphqlFragments'
import { isDesktopModal } from 'utils/helpers'
import {
  EnrollmentCurrentFlow,
  EnrollmentStep,
  IPlan
} from 'modules/enrollment/types'
import {
  addHasProKey,
  addUuidToHasRequiredContributionKey
} from 'modules/enrollment/helpers'
import { getQuery } from 'hooks'
import { UserSubscriptionForEnrollment } from 'modules/profile/userInterfaces'

export interface IEnrollmentStatusDetails {
  label: string
  ctaLabel?: string
  caption?: string
  color: LabelTagColor
  action?: () => void
  viewAsManager?: {
    caption: string
  }
}

export const isEnrollmentStatus = (
  status: EnrollmentStatus
): status is EnrollmentStatus => {
  return listOfEnrollmentStatusValues.includes(status)
}

export function getEnrollmentStatusDetails({
  fund,
  context,
  setEnrollmentModalOpen,
  setUserCurrentFundDraftMutation
}: {
  context: any
  fund: IFundGetEnrollmentStatusDetails
  setEnrollmentModalOpen?: (arg0: boolean) => void
  setUserCurrentFundDraftMutation?: () => Promise<any>
}): IEnrollmentStatusDetails | null {
  const { enrollmentStatus, uuid, establishmentUrl, handle, fundOwner, plan } =
    fund

  if (!isEnrollmentStatus(enrollmentStatus)) return null

  const map: {
    [key in EnrollmentStatus]: IEnrollmentStatusDetails
  } = {
    not_connected: {
      label: 'not connected',
      ctaLabel: 'Connect a 529 plan',
      caption: 'Enroll in a new 529 plan or link your existing account.',
      color: 'danger',
      action: () => {
        setEnrollmentModalOpen && isDesktopModal()
          ? setEnrollmentModalOpen(true)
          : historyRef.current.push(
              buildPath('enrollment', {
                fundUuid: uuid
              })
            )
      },
      viewAsManager: {
        caption: 'Ask the owner of the fund to start the enrollment process'
      }
    },
    resume_setup: {
      label: 'resume setup',
      ctaLabel: 'Resume setup',
      caption: 'Continue your 529 enrollment.',
      color: 'warning',
      action: () => {
        if (
          setEnrollmentModalOpen &&
          isDesktopModal() &&
          setUserCurrentFundDraftMutation
        ) {
          setUserCurrentFundDraftMutation().then(() => {
            let step: EnrollmentStep | undefined = 'start'
            if (fundOwner.currentFundDraft.currentFlow !== 'cyp') {
              addUuidToHasRequiredContributionKey(fund)
              addHasProKey(fundOwner)
              step = resumeSetupDestination(fund)
            }

            if (step === 'overview') {
              historyRef.current.push(
                buildPath('enrollment', {
                  fundUuid: uuid,
                  step
                })
              )
            } else {
              setEnrollmentModalOpen(true)
            }
          })
        } else {
          // For mobile web and mobile app, don't show the modal
          historyRef.current.push(
            buildPath('enrollment', {
              fundUuid: uuid
            })
          )
        }
      },
      viewAsManager: {
        caption: 'Ask the owner of the fund to finish enrolling.'
      }
    },
    requires_account_number: {
      label: 'provide account number',
      ctaLabel: 'Provide account number',
      caption: `Please enter your ${
        plan ? plan.name : ''
      } account number to finalize the enrollment`,
      color: 'warning',
      action: () => {
        historyRef.current.push({
          pathname: buildPath('post-enrollment', {
            fundUuid: uuid
          })
        })
      },
      viewAsManager: {
        caption: 'Ask the owner of the fund to finish enrolling.'
      }
    },
    requires_activation: {
      label: 'activate 529',
      ctaLabel: 'Activate 529',
      caption:
        'Please verify your identity with my529 to finalize your account.',
      color: 'warning',
      action: () => {
        context.mobileAppFromV(1) &&
          sendToMobileApp('verify_identity', establishmentUrl)
        window.open(establishmentUrl, '_blank')
      },
      viewAsManager: {
        caption: 'Ask the owner of the fund to finish enrolling.'
      }
    },
    resume_ssn: {
      label: 'provide beneficiary SSN',
      ctaLabel: 'Provide SSN',
      caption: `To continue 529 enrollment, please provide your child's SSN.`,
      color: 'warning',
      action: () => {
        historyRef.current.push({
          pathname: buildPath('enrollment', {
            fundUuid: uuid
          })
        })
      },
      viewAsManager: {
        caption: 'Ask the owner of the fund to finish enrolling.'
      }
    },
    error: {
      label: 'unable to connect',
      ctaLabel: 'Contact support',
      caption: 'Contact our support team to complete your enrollment.',
      color: 'danger',
      action: () => window.open(config.HELP_URL, '_blank'),
      viewAsManager: {
        caption: 'Contact our support team to complete your enrollment.'
      }
    },
    unknown: {
      label: 'unable to connect',
      ctaLabel: 'Contact support',
      caption: 'Contact our support team to complete your enrollment.',
      color: 'danger',
      action: () => window.open(config.HELP_URL, '_blank'),
      viewAsManager: {
        caption: 'Contact our support team to complete your enrollment.'
      }
    },
    enrolled: {
      label: fund.programManager === 'my529' ? 'enrolled' : 'linked',
      ctaLabel: undefined,
      color: 'success',
      caption: 'Your 529 plan is connected.',
      action: () => {
        historyRef.current.push(
          buildPath('accountInfo', {
            uuidOrHandle: handle
          })
        )
      },
      viewAsManager: {
        caption: 'Your 529 plan is connected.'
      }
    }
  }

  return map[enrollmentStatus]
}

export function isUuid(uuidOrHandle: string) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regexExp.test(uuidOrHandle)
}

export const CREATE_FUND_DRAFT = gql`
  mutation createFundDraft($input: CreateFundDraftInputType!) {
    createFundDraft(input: $input) {
      ...FundDraftFragment
    }
  }
  ${fragments.fundDraft}
`

export function createFund({
  createFundDraft,
  context,
  referrer
}: {
  createFundDraft: (arg0: {
    variables: { input: { currentOnboardingStep: string; currentFlow: string } }
  }) => Promise<any>
  context: any
  referrer?: string
}): void {
  const queryParams = getQuery()
  const history = historyRef.current
  const pathname = buildPath('createFund', { step: 'fund-name' })
  queryParams.append('firstStep', 'fund-name')
  if (referrer) {
    queryParams.append('referrer', referrer)
  }
  const search = queryParams.toString() ? `?${queryParams.toString()}` : ''
  const url = `${pathname}${search}`

  createFundDraft({
    variables: {
      input: {
        currentOnboardingStep: 'fund-name',
        currentFlow: 'safe'
      }
    }
  })
    .then(() => {
      if (context.mobileAppFromV(2)) {
        sendToMobileApp('push_url', `New Fund`, url)
      } else {
        history.push({
          pathname,
          search
        })
      }
    })
    .catch(graphQLErrorHandler(window.alert)) // @TODO - replace with a toast
}

export function resumeSetupDestination({
  programManager,
  plan,
  portfolio
}: IFundGetEnrollmentStatusDetails) {
  let step: EnrollmentStep | undefined = 'start'
  if (programManager === 'my529') {
    if (plan && portfolio) {
      step = 'overview'
    } else {
      step = 'owner-address'
    }
  }
  return step
}

interface IFundGetEnrollmentStatusDetails {
  enrollmentStatus: EnrollmentStatus
  uuid: string
  establishmentUrl: string
  handle: string
  plan: IPlan
  portfolio: Portfolio
  programManager: ProgramManager
  fundOwner: {
    currentFundDraft: {
      currentFlow: EnrollmentCurrentFlow
    }
  } & UserSubscriptionForEnrollment
}

