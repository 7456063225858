import { GuestType } from 'modules/global/types'
import { IFund, ICurrentUser } from '../modules/dashboard/components/Dashboard'
import { IManagedContribution } from 'modules/edit-contributions/EditContributions'
import {
  PricingPlan,
  SubscriptionFrequency,
  UserSubscriptionDisplayStatus
} from 'modules/profile/userInterfaces'

const getUserData = (someBlob: any) => someBlob.currentUser || someBlob

export function hasPaymentMethod(data: any) {
  const user = getUserData(data)
  return (
    (user?.bankAccounts?.length || 0) > 0 ||
    (user?.creditCards?.length || 0) > 0
  )
}

export function hasActivePaymentMethod(data: any) {
  if (!data) return false
  const user = getUserData(data)
  const activeBankAccounts = (user?.bankAccounts || []).filter(
    (b: any) => (b?.plaidVerificationStatus || '').indexOf('pending') === -1
  )

  return (
    (activeBankAccounts.length || 0) > 0 || (user?.creditCards?.length || 0) > 0
  )
}

export const editableContributions = (
  contributions: IManagedContribution[]
) => {
  if (!contributions) return []
  if (contributions.length === 0) return []
  return contributions.filter((contribution: IManagedContribution) => {
    const { frequency, nextPaymentDate, state } = contribution
    return (
      state === 'invalid' ||
      (frequency === 'onetime' && state === 'scheduled') ||
      !!nextPaymentDate
    )
  })
}

export const ownContributions = (
  contributions: IManagedContribution[],
  currentUser: {
    id: string
  }
) => {
  if (!contributions) return []
  if (contributions.length === 0) return []

  return editableContributions(contributions).filter(
    (contribution: IManagedContribution) => {
      return contribution.user.id === currentUser.id
    }
  )
}

export function isGuest(user: { guest: GuestType }) {
  return user?.guest === 'current'
}

export function orderUserFunds(user: ICurrentUser): IFund[] {
  const userId = '' + user.id

  const dependenciesUpdatedAtComparator = (a: IFund, b: IFund) => {
    const dateA = new Date(a.dependenciesUpdatedAt).valueOf()
    const dateB = new Date(b.dependenciesUpdatedAt).valueOf()
    return dateB - dateA
  }

  const ownFunds = user.funds.filter((fund) => fund.fundOwner.id === userId).sort(dependenciesUpdatedAtComparator)
  const otherFunds = user.funds.filter((fund) => fund.fundOwner.id !== userId).sort(dependenciesUpdatedAtComparator)
  return ownFunds.concat(otherFunds)
}

export function shouldDisplaySubscription(user: UserSubscriptionDisplayStatus) {
  return true
}

export function isFreeTier(user: { pricingPlan: PricingPlan }) {
  return user.pricingPlan === 'backer-free' || user.pricingPlan === 'free-tier'
}

export function isPro(user: { pricingPlan: PricingPlan }) {
  return user.pricingPlan === 'backer-pro' || user.pricingPlan === 'paid-tier'
}

export function isProMonthly(user: {
  pricingPlan: PricingPlan
  subscriptionFrequency: SubscriptionFrequency
}) {
  return isPro(user) && user.subscriptionFrequency === 'monthly'
}

export function isProAnnual(user: {
  pricingPlan: PricingPlan
  subscriptionFrequency: SubscriptionFrequency
}) {
  return isPro(user) && user.subscriptionFrequency === 'annually'
}
