import classNames from 'classnames'
import gql from 'graphql-tag'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { get, isEmpty } from 'lodash'
import {
  Button,
  Stack,
  ListItem,
  Icon,
  LabelTag,
  Modal,
  Navbar,
  InfoMesssage
} from '@collegebacker/backer-ui/ui'

import { SelectPaymentMethodListItem } from 'modules/contribution/components/SelectPaymentMethodListItem'

import { getConfirmation, isMobileApp, minWidthOfScreen } from 'utils'
import { ConfirmationAlert } from 'modules/contribution/components/ConfirmationAlert'
import { buildPath } from 'routes'
import { Loading } from 'components'

import noPaymentMethodImg from 'static/img/add_payment_method.svg'
import styles from './EditPaymentMethods.module.scss'
import { BankAccount, CreditCard } from 'modules/contribution/types'
import { useBackNavigation } from 'hooks/useBackNavigation'

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods {
    currentUser {
      id
      countFunds
      variant
      defaultPaymentMethodType
      defaultPaymentMethodId
      bankAccounts {
        id
        accountNumber
        bankLabel
        accountLabel
        removable
        removableReason
        removableReasonHelper
        name
      }
      creditCards {
        id
        bankLabel
        accountLabel
        removable
        removableReason
        removableReasonHelper
        expiration
      }
    }
  }
`
const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser($input: UpdateCurrentUserInputType!) {
    updateCurrentUser(input: $input) {
      id
      defaultPaymentMethodType
      defaultPaymentMethodId
    }
  }
`

const DELETE_PAYMENT_METHOD = gql`
  mutation DeletePaymentMethod($input: DeletePaymentMethodInputType!) {
    deletePaymentMethod(input: $input) {
      success
    }
  }
`

export const EditPaymentMethods = () => {
  const history = useHistory()
  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER)
  const { data, loading: dataLoading } = useQuery(GET_PAYMENT_METHODS, {
    fetchPolicy: 'network-only'
  })
  const [deletePaymentMethod, { loading: deletePaymentMethodLoading }] =
    useMutation(DELETE_PAYMENT_METHOD, {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_PAYMENT_METHODS }]
    })

  const [showModal, setShowModal] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<IPaymentMethod>()
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('')
  const [confirmation, setConfirmation] = useState<any>(null)

  const backButtonClick = useBackNavigation()

  useEffect(() => {
    const maybeConfirmation = getConfirmation()
    if (!isEmpty(maybeConfirmation)) {
      setConfirmation(maybeConfirmation)
    }
  }, [])

  const updateCurrentUserDefaultPaymentMethod = (type: string, id: string) => {
    return updateCurrentUser({
      variables: {
        input: { defaultPaymentMethodType: type, defaultPaymentMethodId: id }
      }
    })
  }

  const dpmId = extractDefaultPaymentMethodId(data)
  const paymentMethods = extractPaymentMethodsData(data)

  const defaultPaymentMethodId = defaultPaymentMethod || dpmId

  const setDefaultPaymentMethodFn = (newPmId: string) => {
    setDefaultPaymentMethod(newPmId)
    const [type, id] = newPmId.split(':')
    updateCurrentUserDefaultPaymentMethod(type, id)
  }

  const removePaymentMethod = async (pmIdToRemove: string) => {
    const [type, id] = pmIdToRemove.split(':')
    await deletePaymentMethod({ variables: { input: { type, id } } })
  }

  const selectedPaymentMethodIsDefault =
    defaultPaymentMethodId === selectedPaymentMethod?.pmId
  const selectedPaymentMethodIsRemovable = selectedPaymentMethod?.removable
  const selectedPaymentMethodIsRemovableButDefault =
    selectedPaymentMethodIsRemovable && selectedPaymentMethodIsDefault

  if (dataLoading) return <Loading />

  return (
    <>
      {!isMobileApp() && <Navbar backButtonClick={backButtonClick} />}
      <div className={styles.container}>
        <div className="grid">
          <ConfirmationAlert
            confirmation={confirmation}
            setConfirmation={setConfirmation}
          />
          <div className="subgrid-desktop-3-10 subgrid-tablet-1-12 subgrid-mobile-1-6">
            <Stack className="subgrid-desktop-1-8 subgrid-tablet-1-12 subgrid-mobile-1-6">
              <Stack className="grid-desktop-1-4 grid-tablet-1-4 grid-mobile-1-6">
                <h1 className="typo-app-title-small">Your payment methods</h1>
              </Stack>
              <Stack
                spacing={40}
                className="grid-desktop-5-8 grid-tablet-5-12 grid-mobile-1-6"
              >
                {paymentMethods.length === 0 && (
                  <Stack spacing={30}>
                    <div className={styles.gradientContainer}>
                      <div className={styles.gradient} />
                      <img
                        className={styles.noPaymentMethodImg}
                        src={noPaymentMethodImg}
                        alt=""
                      />
                      <p
                        className={classNames(
                          'typo-app-body-caption',
                          styles.showSupport
                        )}
                      >
                        You don't have any payment methods.
                      </p>
                    </div>
                    <Button
                      onClick={() =>
                        history.push(buildPath('addPaymentMethod'))
                      }
                      label="Add payment method"
                      size={minWidthOfScreen(621) ? 'small' : 'default'}
                      maxWidth={minWidthOfScreen(621) ? 213 : 335}
                    />
                  </Stack>
                )}
                {paymentMethods.length > 0 && (
                  <Stack spacing={20}>
                    {paymentMethods.map((paymentMethod: any, idx: number) => {
                      const { pmId, title, description, icon } = paymentMethod
                      const checked = defaultPaymentMethodId === pmId

                      return (
                        <SelectPaymentMethodListItem
                          key={pmId}
                          chevron={true}
                          topLabel={
                            checked && (
                              <LabelTag
                                label="default"
                                size="small"
                                color="velvet"
                              />
                            )
                          }
                          leftContent={false}
                          title={title}
                          description={description}
                          icon={icon}
                          onClick={() => {
                            setSelectedPaymentMethod(paymentMethod)
                            setShowModal(true)
                          }}
                          divider={true}
                          middleContentPaddingRight={70}
                        />
                      )
                    })}
                    <Button
                      onClick={() =>
                        history.push(buildPath('addPaymentMethod'))
                      }
                      label="Add new payment method"
                      maxWidth={335}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </div>
        </div>
      </div>
      <Modal
        isBottomSheet
        isOpen={showModal}
        onCloseClick={() => setShowModal(false)}
      >
        <ListItem
          topLabel={
            selectedPaymentMethodIsDefault && (
              <LabelTag label="default" size="small" color="velvet" />
            )
          }
          title={selectedPaymentMethod?.title}
          description={selectedPaymentMethod?.description}
          rightContent={<Icon name={selectedPaymentMethod?.icon!} />}
          divider={false}
        />
        {!selectedPaymentMethodIsRemovable && (
          <InfoMesssage
            title={selectedPaymentMethod?.removableReason}
            caption={selectedPaymentMethod?.removableReasonHelper}
            mode="velvet"
            icon="alert"
            className={styles.infoMessage}
          />
        )}
        {selectedPaymentMethodIsRemovableButDefault && (
          <InfoMesssage
            title="This payment method is currently set as default"
            caption="To remove this payment method, please select another payment method as your default."
            mode="velvet"
            icon="alert"
            className={styles.infoMessage}
          />
        )}
        <Stack spacing={15}>
          {!selectedPaymentMethodIsDefault && (
            <Button
              mode="outline"
              onClick={() => {
                setDefaultPaymentMethodFn(selectedPaymentMethod?.pmId!)
                setShowModal(false)
              }}
              label="Set as default"
              minWidth={335}
            />
          )}
          <Button
            mode="outline"
            label="Remove"
            onClick={() => {
              selectedPaymentMethodIsRemovable &&
                removePaymentMethod(selectedPaymentMethod.pmId)
              setShowModal(false)
            }}
            minWidth={335}
            disabled={
              selectedPaymentMethodIsRemovableButDefault ||
              !selectedPaymentMethodIsRemovable ||
              deletePaymentMethodLoading
            }
          />
        </Stack>
      </Modal>
    </>
  )
}

interface ICurrentUser {
  bankAccounts: BankAccount[]
  creditCards: CreditCard[]
}

interface IPaymentMethod {
  pmId: string
  title: string
  description: string
  icon: IconTypes
  removable: boolean
  removableReason: string
  removableReasonHelper: string
  type: string
}
function extractPaymentMethodsData(
  queryResult: ICurrentUser
): IPaymentMethod[] {
  const bankAccounts = get(queryResult, 'currentUser.bankAccounts', [])
  const creditCards = get(queryResult, 'currentUser.creditCards', [])
  return bankAccounts.concat(creditCards).map((pm: any) => {
    const { accountNumber, bankLabel, accountLabel, name, expiration, id } = pm
    pm.type = accountNumber ? 'ba' : 'cc'
    pm.pmId = `${pm.type}:${id}`

    pm.title =
      pm.type === 'ba'
        ? `${bankLabel} ${name} ${accountLabel}`
        : `${bankLabel} ${accountLabel} ${expiration}`
    pm.description = pm.type === 'ba' ? 'No credit/debit processing fee' : 'Credit card (3% fee)'
    pm.icon = pm.type === 'ba' ? 'bank-account' : 'credit-card'
    return pm
  })
}

function extractDefaultPaymentMethodId(queryResult: ICurrentUser) {
  const dpmType = get(queryResult, 'currentUser.defaultPaymentMethodType')
  const dpmId = get(queryResult, 'currentUser.defaultPaymentMethodId')
  if (dpmType && dpmId) return `${dpmType}:${dpmId}`
}
