import moment from 'moment'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { useState } from 'react'
import {
  Button,
  Icon,
  LabelTag,
  Modal,
  Stack
} from '@collegebacker/backer-ui/ui'
import config from 'config'
import stars from 'static/img/stars.svg'
import {
  PricingPlan,
  SubscriptionFrequency,
  UserSubscriptionDates
} from '../userInterfaces'
import { SelectSubscription, TSelectedPlan } from './SelectSubscription'
import { isProAnnual, isProMonthly } from 'utils/user'
import userFragments from 'modules/profile/graphqlFragments'

import styles from './HasSubscription.module.scss'

const UPDATE_USER_PRICING_PLAN = gql`
  mutation UpdateUserPricingPlan($input: UpdateUserPricingPlanInputType!) {
    updateUserPricingPlan(input: $input) {
      id
      email
      ...SubscriptionFragmentForEnrollment
    }
  }
  ${userFragments.subscriptionForEnrollment}
`

const HasSubscription = ({
  onSuccess,
  onCancel,
  enrollment,
  selectedPlan,
  setSelectedPlan,
  currentUser: {
    pricingPlan,
    subscriptionDueOn,
    subscriptionFrequency
  }
}: HasSubscriptionProps) => {
  const [error, setError] = useState('')
  const [updateUserPricingPlan, { loading }] = useMutation(
    UPDATE_USER_PRICING_PLAN
  )

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Stack spacing={30} className={styles.container}>
      <div>
        <h1
          className="typo-app-title-medium-adaptive"
          style={{ marginBottom: 0 }}
        >
          Manage membership
        </h1>
        <Stack spacing={20}>
          <div className={styles.card}>
            <p className="typo-app-body-paragraph">
              {getIntro({
                pricingPlan,
                subscriptionDueOn,
                subscriptionFrequency,
                enrollment
              })}
            </p>
            <img src={stars} className={styles.stars} alt="" />
          </div>
          <Stack spacing={10}>
            <p
              className={classNames('typo-app-body-caption', styles.lightLabel)}
            >
              What you get
            </p>
            <ul className={styles.list}>
              <li className="typo-app-body-caption">
                <Icon name="tickmark-in-circle" /> Enroll in top-rated 529 or connect your own
              </li>
              <li className="typo-app-body-caption">
                <Icon name="tickmark-in-circle" /> Open or link unlimited 529 plans
              </li>
              <li className="typo-app-body-caption">
                <Icon name="tickmark-in-circle" /> No platform fees on your contributions / gifts
              </li>
              <li className="typo-app-body-caption">
                <Icon name="tickmark-in-circle" /> Priority customer support
              </li>
            </ul>
          </Stack>
        </Stack>
      </div>
      <div>
        {!enrollment &&
          isProMonthly({ pricingPlan, subscriptionFrequency }) && (
            <div className={styles.cardGradient}>
              <LabelTag
                label="Best deal"
                color="success"
                size="small"
                className={styles.labelTag}
              />
              <p>Switch to annual billing to save 16%</p>
            </div>
          )}
        {enrollment ? (
          <Stack spacing={20}>
            <Button
              label="Update membership"
              maxWidth={335}
              mode="ghost"
              onClick={() => setIsOpen(true)}
            />
            <Button label="Continue" minWidth={335} onClick={onCancel} />
          </Stack>
        ) : (
          <Button
            label="Update membership"
            minWidth={335}
            mode="outline"
            onClick={() => setIsOpen(true)}
          />
        )}

        <Modal
          isOpen={isOpen}
          onCloseClick={() => {
            setIsOpen(false)
          }}
          isBottomSheet={true}
        >
          {error ? (
            <Stack spacing={30}>
              <h1
                className="typo-app-title-medium-adaptive"
                style={{ marginBottom: 0 }}
              >
                Please contact support
              </h1>
              <p>{error}</p>
              <Button
                label="Contact support"
                tag="a"
                target="_blank"
                rel="noopener noreferrer"
                href={config.HELP_URL}
              />
            </Stack>
          ) : (
            <SelectSubscription
              subscriptionDueOn={subscriptionDueOn}
              updateUserPricingPlan={updateUserPricingPlan}
              loading={loading}
              onSuccess={onSuccess}
              setError={setError}
              pricingPlan={pricingPlan}
              subscriptionFrequency={subscriptionFrequency}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              onCancel={onCancel}
            />
          )}
        </Modal>
      </div>
    </Stack>
  )
}

function getIntro({
  pricingPlan,
  subscriptionDueOn,
  subscriptionFrequency,
  enrollment
}: {
  pricingPlan: PricingPlan
  subscriptionDueOn: Date
  subscriptionFrequency: SubscriptionFrequency
  enrollment?: boolean
}) {
  const annual = isProAnnual({ pricingPlan, subscriptionFrequency })
  if (enrollment) {
    return (
      <>
        You've selected {annual ? 'an annual' : 'a monthly'}{' '}
        <span className={styles.highlight}>Backer Pro</span> subscription.
      </>
    )
  }
  const formattedSubscriptionDueOn =
    moment(subscriptionDueOn).format('MMM D, YYYY')
  return (
    <>
      You are enjoying the full Backer experience with{' '}
      <span className={styles.highlight}>Backer Pro</span>. Your next renewal is
      on {formattedSubscriptionDueOn}.
    </>
  )
}

export default HasSubscription

interface HasSubscriptionProps {
  selectedPlan?: TSelectedPlan
  setSelectedPlan: (arg0: TSelectedPlan) => void
  onSuccess: (subscriptionDates: UserSubscriptionDates) => void
  onCancel: () => void
  enrollment?: boolean
  currentUser: {
    pricingPlan: PricingPlan
    subscriptionPaidUntil: Date
    subscriptionDueOn: Date
    subscriptionFrequency: SubscriptionFrequency
  }
}
