import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Frequency } from 'modules/contribution/types'

const DEFAULT_CONTRIBUTION_FEES = {
  processingFee: 0,
  subscriptionFee: 0,
  fullAmount: 0,
  unbundledAmount: 0,
}

const CONTRIBUTION_FEES = gql`
  query ContributionFees($input: ContributionFeesInputType!) {
    contributionFees(input: $input) {
      subscriptionFee
      processingFee
      fullAmount
      unbundledAmount
    }
  }
`

export function useContributionFeesQuery(
  input?: {
    paymentMethodType?: 'credit-card' | 'bank-account'
    frequency: Frequency
    amount: number
    fundUuid?: string
    contributionId?: string
  }) {
  const { loading, data } = useQuery(CONTRIBUTION_FEES, {
    fetchPolicy: 'cache-and-network',
    variables: { input },
    skip: !input?.paymentMethodType
  })

  const result = data?.contributionFees || {}

  return { ...DEFAULT_CONTRIBUTION_FEES, ...result, loading }
}
